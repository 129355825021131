<script>
export default {
    AvatarUrl: "https://avatars.githubusercontent.com/u/55633040?v=4",
    Name: "Tas33n",
    ShortBio: " Tech magician, crafting wonders with every stroke of the keyboard.",
    About: `
- 👋 Hi, I’m Farhan Isteak Taseen, a full-stack web developer from BD.<br>
- 💻 I build responsive websites using HTML, CSS, JS and Bootstrap for the frontend and PHP, Laravel and Node.js for the backend.<br>
- 🤖 I create social chatbots for Telegram, Facebook, and Discord.<br>
- 🤝 I’m interested in projects focused on full-stack development, especially with Node.js, PHP, and Laravel.<br>
- 📚 Currently, I’m advancing my skills in Laravel and improving my expertise in Node.js.<br>
- 😃 I speak English, Bengali (Native), and basic Hindi.<br>
- 📫 Reach me at <a href="mailto://farhanisteak84@gmail.com" title="Farhan Isteak Taseen">farhanisteak84@gmail.com</a> or on Telegram at <a href="https://t.me/lamb3rt" title="Lambert" target="_blank">@lamb3rt</a>.<br>
- 🌟 Explore my projects on GitHub: <a href="https://github.com/tas33n" title="Tas33n's GitHub" target="_blank">github.com/tas33n</a><br>
`,
    DisplayProjectCard: true,
    ProjectInfo: [
        {
            "id": 0,
            "ProjectImg": ["https://i.ibb.co/sFDzPks/Screenshot-2024-08-22-232746.png"],
            "ProjectName": "Destination - Medical Coaching Center Website",
            "ProjectDesc": "A professional website designed for a medical coaching center.",
            "ProjectLink": "https://github.com/tas33n/InstaFilter"
        },
        {
            "id": 1,
            "ProjectImg": ["https://i.ibb.co/NChdgHh/Screenshot-2024-08-23-235323.png"],
            "ProjectName": "MisfitsDEV API Management Dashboard",
            "ProjectDesc": "A web portal for managing and sharing free or premium APIs with secure authorization.",
            "ProjectLink": "https://api.anbusec.xyz/"
        },
        {
            "id": 2,
            "ProjectImg": ["https://i.ibb.co/2SC8NnG/Screenshot-2024-08-17-000550.png"],
            "ProjectName": "InstaSaver - Instagram UserScript",
            "ProjectDesc": "A user script that adds a convenient download button to Instagram posts.",
            "ProjectLink": "https://github.com/tas33n/instaSaver"
        },
        {
            "id": 3,
            "ProjectImg": ["https://i.ibb.co/dgZYzYG/Screenshot-2024-08-22-230233.png"],
            "ProjectName": "JHGFX - Graphics Design Showcase",
            "ProjectDesc": "A blogger theme for showcasing graphic design works.",
            "ProjectLink": "https://jobayer-hossen.blogspot.com/"
        },
        {
            "id": 4,
            "ProjectImg": ["https://i.ibb.co/4MZV09q/Screenshot-3.png"],
            "ProjectName": "Cyber-Punk Facebook Theme - Stylus",
            "ProjectDesc": "A cyberpunk-inspired theme for Facebook's web interface, implemented via Stylus.",
            "ProjectLink": "https://github.com/tas33n/Cyber-Punk-facebook"
        },
        {
            "id": 5,
            "ProjectImg": ["https://i.ibb.co/vkNr3vp/portauthor.jpg"],
            "ProjectName": "PortAuthor BD - Web Application",
            "ProjectDesc": "A dedicated web application for the Chittagong Port Authority in Bangladesh.",
            "ProjectLink": ""
        },
        {
            "id": 6,
            "ProjectImg": ["https://i.ibb.co/Ss6q7pY/gogoanime-so-ads-main.jpg"],
            "ProjectName": "GogoAnime - NodeJS Module",
            "ProjectDesc": "A lightweight Node.js module for searching and streaming anime from GoGoAnime.",
            "ProjectLink": "https://github.com/tas33n/GogoAnime-NodeJS"
        },
        {
            "id": 7,
            "ProjectImg": ["https://i.ibb.co/c2X3Drp/Screenshot-2024-08-22-233941.png"],
            "ProjectName": "Telegram Invite Widget",
            "ProjectDesc": "A tool for creating customizable invite widgets for Telegram groups or channels.",
            "ProjectLink": "https://github.com/tas33n/telegram-invite-Widget"
        },
        {
            "id": 8,
            "ProjectImg": ["https://i.ibb.co/FK5MQyf/preview.jpg"],
            "ProjectName": "AsuraScans Download Bot",
            "ProjectDesc": "A Telegram bot for downloading manga/manhwa from AsuraScans in PDF format.",
            "ProjectLink": "https://github.com/tas33n/AsuraScans-DL-Bot"
        },
        {
            "id": 9,
            "ProjectImg": ["https://i.ibb.co/bFtyGDR/Screenshot-2024-08-23-000603.png"],
            "ProjectName": "GitHub Portfolio - Project Showcase",
            "ProjectDesc": "A portfolio website showcasing GitHub repositories and projects.",
            "ProjectLink": "https://tas33n.github.io/"
        },
        {
            "id": 10,
            "ProjectImg": ["https://i.ibb.co/7C9Dfqg/Screenshot-2024-08-23-235834.png"],
            "ProjectName": "SMS Panel",
            "ProjectDesc": "An SMS management system for bulk and individual messaging with subscription support and an API.",
            "ProjectLink": "https://sms.anbusec.xyz/"
        }

    ]

}
</script>
