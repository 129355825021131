<template>
  <el-row style="overflow:hidden" type="flex" justify="space-between">
    <transition-group
      appear
      name="animate__animated animate__fadeInUp"
      appear-class="animate__fadeInUp"
      appear-to-class="animate__fadeInUp"
    >
      <el-col 
        :xs="24" 
        :sm="24" 
        :md="{span: 11, offset: 1}" 
        :lg="{span: 11, offset: 1}" 
        :xl="{span: 7, offset: 1}" 
        :span="11" 
        v-for="(item, index) in ProjectInfo" 
        :key="item.id" 
        :class="'animate__delay-'+0.5*index+'s'"
      >
        <el-card 
          ref="projectCard"
          class="project-card"
          style="margin-top: 10px;"
          :body-style="{ padding: '0px', display: 'flex', flexDirection: 'column' }"
        >
          <el-image 
            :src="item.ProjectImg[0]" 
            :preview-src-list="item.ProjectImg" 
            style="width: 100%; height:200px; display: block;" 
            fit="cover"
          ></el-image>
          <div class="card-content">
            <h3 class="project-title">{{ item.ProjectName }}</h3>
            <p class="project-desc">{{ item.ProjectDesc }}</p>
            <div class="bottom clearfix">
              <el-link 
                type="primary" 
                :href="item.ProjectLink" 
                class="button" 
                target="_blank" 
                v-if="item.ProjectLink"
              >
                Learn More
              </el-link>
            </div>
          </div>
        </el-card>
      </el-col>
    </transition-group>
  </el-row>
</template>

<style>
.project-title {
  font-size: 16px;
  color: #333;
  margin: 0 0 8px 0;
}

.project-desc {
  font-size: 14px;
  color: #666;
  margin: 0 0 12px 0;
}

.card-content {
  padding: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button {
  padding: 0;
  float: right;
  color: #1E90FF;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.el-row {
  display: flex;
  flex-wrap: wrap;
}

.el-col {
  display: flex;
  flex-direction: column;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>

<script>
export default {
  name: 'ProjectCard',
  props: {
    ProjectInfo: Array
  },
  mounted() {
    this.equalizeCardHeights();
    window.addEventListener('resize', this.equalizeCardHeights);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.equalizeCardHeights);
  },
  methods: {
    equalizeCardHeights() {
      this.$nextTick(() => {
        const cols = this.$el.querySelectorAll('.el-col');
        const rowHeights = [];

        cols.forEach((col, index) => {
          const card = col.querySelector('.project-card');
          const cardHeight = card.offsetHeight;

          if (index % 2 === 0) {
            rowHeights.push(cardHeight);
          } else {
            const maxHeight = Math.max(rowHeights.pop(), cardHeight);
            card.style.height = `${maxHeight}px`;
            cols[index - 1].querySelector('.project-card').style.height = `${maxHeight}px`;
          }
        });
      });
    }
  }
}
</script>
